<template>
  <div class="home-index">
    <headerCom></headerCom>
    <div class="main-contain">
      <div class="flex-row-h-center header-crumbs">
        <div class="fire_container">
          <span class="one">实时播报</span>
          <span class="two">> 文章详情</span>
        </div>
      </div>
      <div class="fire_container flex-row">
        <div class="detail">
          <div class="title">{{ newsDetail.title }}</div>
          <div class="source-time">
            <span>{{ newsDetail.source }}</span>
            <span class="time">{{ newsDetail.time }}</span>
          </div>
          <div class="hr-line"></div>
          <div class="content">这里是富文本</div>
          <div class="hr-line"></div>
          <div class="tip">
            <div class="special-reminder">
              <span class="label">特别提醒：</span>
              <span>如果我们使用了您的图片，请作者与</span>
              <span class="link">本站联系</span>
              <span>索取稿酬。如您不希望作品出现在本站，可联系我们要求撤下您的作品。</span>
            </div>
          </div>
        </div>
        <div class="recommend-article">
          <div class="title">推荐文章</div>
          <div class="flex-row-h-center article-item" v-for="item in recommendArticleList" :key="item.id">
            <span class="one-line">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <footerCom></footerCom>
  </div>
</template>

<script>
import headerCom from '@/components/header'
import footerCom from '@/components/footer'

export default {
  name: 'homeIndex',
  components: {
    headerCom,
    footerCom
  },
  data () {
    return {
      newsDetail: {
        title: '辽宁警方通报山火原因：居民上坟烧香引发！哈尔滨：清明节期间禁烧冥纸冥币',
        source: '每日经济新闻',
        time: '2023-04-02 20:40:5'
      },
      recommendArticleList: [
        {
          id: 1,
          title: '物资管理系统助力我国空物资管理系统助力我国空'
        },
        {
          id: 2,
          title: '“芯”动时刻—国芯物联携RFID国芯物联携RFID'
        },
        {
          id: 3,
          title: '国芯物联重磅亮相国际物联网国芯物联重磅亮相国际物联网'
        }
      ]
    }
  },
  mounted () {
    const size = 0.8
    document.body.style.zoom = size
    document.body.style.cssText += '; -moz-transform: scale(' + size + ');-moz-transform-origin: 0 0; '
  }
}
</script>

<style lang="scss" scoped>
.home-index {
  position: relative;

}

.main-contain {
  padding-top: 64px;
  overflow: hidden;
  background: #151518 url("../../assets/image/bg1.png") top center no-repeat;

  .header-crumbs {
    height: 86px;
    background: #232327;

    .one {
      font-size: 18px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      line-height: 22px;
    }

    .two {
      margin-left: 5px;
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 22px;
    }
  }

  .fire_container.flex-row {
    margin-top: 60px;
    margin-bottom: 80px;

    .detail {
      flex: 1;
      min-height: 800px;
      padding: 40px 60px;
      margin-right: 16px;
      background: #232327;
      border-radius: 4px;

      .title {
        font-size: 30px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 36px;
        text-align: center;
      }

      .source-time {
        margin-top: 12px;
        font-size: 15px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
        text-align: center;

        .time {
          margin-left: 2em;
        }
      }

      .hr-line {
        height: 1px;
        margin-top: 20px;
        margin-bottom: 20px;
        background: rgba(255, 255, 255, 0.1);
      }

      .content {
      }

      .tip {
        .special-reminder {
          font-size: 15px;
          color: rgba(255, 255, 255, 0.70);

          .label {
            color: rgba(255, 33, 33, 0.70);
          }
        }
      }
    }

    .recommend-article {
      width: 296px;
      height: max-content;
      padding: 40px 24px 40px 32px;
      min-height: 376px;
      background: #232327;
      border-radius: 4px;

      .title {
        margin-bottom: 6px;
        font-size: 23px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
      }

      .article-item {
        padding: 15px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        cursor: pointer;

        &::before {
          content: '';
          width: 6px;
          min-width: 6px;
          height: 6px;
          min-height: 6px;
          margin-right: 8px;
          background: #46A0FF;
          border-radius: 50%;
        }
      }
    }

  }
}
</style>
