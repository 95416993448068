import request from '@/utils/request'

export function insertPerson (data) {
  return request({
    url: '/api/blade-person/insertPerson',
    method: 'post',
    data
  })
}

export function getNewsPage (params) {
  return request({
    url: '/api/blade-website/news/page',
    method: 'get',
    params
  })
}
