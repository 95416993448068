<template>
  <div class="flex-row-space-between-hCenter">
    <div class="img-box">
      <img src="@/assets/logo.png" alt="">
    </div>
    <div class="flex-row nav">
      <span class="title" @click="onChange(item)" :class="{'title-active': activeKey === item.key}"
        v-for="item in navList"
        :key="item.key">{{ item.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navList: [
        {
          key: 'home',
          title: 'Home'
        },
        // {
        //   key: 'broadcast',
        //   title: '实时播报'
        // },
        {
          key: 'skyInsight',
          title: '体验skyInsight',
          url: 'https://starvision.answer12.com'
        },
        {
          key: 'about',
          title: '关于我们',
          url: 'https://www.starvision.cn/zh/welcome'
        }
      ],
      activeKey: 'home'
    }
  },
  created () {
    this.activeKey = 'home'
  },
  methods: {
    onChange (item) {
      this.activeKey = item.key
      if (item.url) {
        window.open(item.url, '_self')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.img-box img {
  width: 110px;
  height: 38px;
  cursor: pointer;
}

.nav {
  .title {
    height: 64px;
    line-height: 64px;
    margin-left: 60px;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
  }

  .title-active {
    font-weight: 500;
    color: #46A0FF;
  }
}
</style>
