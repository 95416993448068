<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

import { isMobileDevice } from '@/utils/common'

export default {
  mounted () {
    this.$Message.config({
      top: 80,
      duration: 3
    })
    window.addEventListener('resize', function () {
      // 在窗口大小变化时执行的代码
      window.location.reload()
    })
  },
  created () {
    if (isMobileDevice()) {
      this.$router.push('/appIndex')
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  font-size: 16px;
}
</style>
