<template>
  <div class="fire_container flex-row real-time-broadcast">
    <div class="left">
      <div class="flex-column title">
        <span class="en">Real-time Broadcast</span>
        <span class="zh">实时播报</span>
      </div>
      <img class="cover" src="https://forest-fire.oss-cn-hangzhou.aliyuncs.com/image/cover.png" alt="">
    </div>
    <div class="right">
      <div class="flex-center-row header-broadcast">
        <span @click="onChangeActive(0)" class="title" :class="{'active-title': activeNav === 0}">火情监测信息播报</span>
        <span class="cut-line"></span>
        <span @click="onChangeActive(1)" class="title" :class="{'active-title': activeNav === 1}">十大风险地区评估播报</span>
        <span class="cut-line"></span>
        <span @click="onChangeActive(2)" class="title" :class="{'active-title': activeNav === 2}">国内林火防控新闻动态</span>
      </div>
      <div v-if="activeNav === 0" class="flex-center-row border">
        <img class="left-line" src="../../../assets/icon/border1.png" alt="">
        <img class="dot" src="../../../assets/icon/round_dot.png" alt="">
        <img class="right-line" src="../../../assets/icon/border2.png" alt="">
      </div>
      <div v-if="activeNav === 1" class="flex-center-row border">
        <img class="center-line" src="../../../assets/icon/border1.png" alt="">
        <img class="dot" src="../../../assets/icon/round_dot.png" alt="">
        <img class="center-line" src="../../../assets/icon/border2.png" alt="">
      </div>
      <div v-if="activeNav === 2" class="flex-center-row border">
        <img class="right-line" src="../../../assets/icon/border1.png" alt="">
        <img class="dot" src="../../../assets/icon/round_dot.png" alt="">
        <img class="left-line" src="../../../assets/icon/border2.png" alt="">
      </div>
      <div class="news-list">
        <div class="flex-row item" :class="{'item-active': activeNav === 0}" @click="goDetail(item)"
          v-for="item in newsList" :key="item.id">
          <span class="time" v-if="activeNav !== 1">{{ item.newsTime | formatDate }}</span>
          <div class="flex-column title-box">
            <Tooltip max-width="380" :content="item.title" placement="top">
              <span class="one-line title">{{ item.title }}</span>
            </Tooltip>
            <span v-if="false" class="sub-title">{{ item.subTitle }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsPage } from '@/api/api'
import { formatDate } from '@/utils/date'

export default {
  name: 'realTimeBroadcast',
  data () {
    return {
      activeNav: 0,
      newsList: []
    }
  },
  created () {
    this.getList()
  },
  filters: {
    formatDate (date) {
      return formatDate(date, 'yyyy-mm-dd')
    }
  },
  methods: {
    onChangeActive (index) {
      this.activeNav = index
      this.newsList = []
      this.getList()
    },
    goDetail (item) {
      if (item.link && (item.link.indexOf('http') !== -1 || item.link.indexOf('https') !== -1)) {
        window.open(item.link, '_self')
      }
    },
    getList () {
      getNewsPage({
        newsType: this.activeNav
      }).then(res => {
        this.newsList = res.records || []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.real-time-broadcast {
  margin-top: 80px;

  .left {
    .title {
      .en {
        font-size: 14px;
        color: #999999;
      }

      .zh {
        font-size: 32px;
        color: #FFFFFF;
      }
    }

    .cover {
      width: 550px;
      height: 605px;
      margin-top: 40px;
      object-fit: contain;
      border-radius: 10px;
    }
  }

  .right {
    flex: 1;
    overflow: hidden;

    .header-broadcast {
      .cut-line {
        width: 1px;
        height: 20px;
        background-color: #FFFFFF;
      }

      .title {
        width: 33%;
        text-align: center;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
        cursor: pointer;
        line-height: 50px;
      }

      .active-title {
        font-size: 16px;
        color: #46A0FF;
      }
    }

    .border {
      width: 100%;
      overflow: hidden;

      .left-line {
        width: 98px;
        height: 1px;
      }

      .dot {
        width: 12px;
        height: 12px;
      }

      .right-line {
        width: 634px;
        height: 1px;
      }

      .center-line {
        width: 366px;
        height: 1px;
      }
    }

    .news-list {
      padding-top: 24px;
      padding-left: 50px;

      .item {
        padding: 20px 0;
        border-bottom: 1px solid transparent;
        border-image: url(../../../assets/icon/border3.png) 30 round;

        .time, .title {
          font-size: 20px;
          color: rgba(255, 255, 255, 0.9);
          line-height: 50px;
        }

        .time {
          min-width: 169px;

        }

        .title-box {
          flex: 1;

          .sub-title {
            margin-top: 4px;
            font-size: 16px;
            color: #B9B1AE;
            line-height: 28px;
          }
        }
      }

      .item-active {
        cursor: pointer;
      }
    }
  }
}
</style>
