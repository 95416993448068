<template>
  <div class="fire_container help-form">
    <div class="flex-column title">
      <span class="en">What can we do for you?</span>
      <span class="zh">我们可以为您做点什么？</span>
    </div>
    <Form class="form1" :model="formItem" gu label-position="top">
      <Row :gutter="48">
        <Col span="12" class-name="form-item-col">
          <FormItem label="姓名">
            <Input v-model="formItem.name" maxlength="20" placeholder="请填写..."></Input>
          </FormItem>
        </Col>
        <Col span="12" class-name="form-item-col">
          <FormItem label="公司名称">
            <Input v-model="formItem.companyName" maxlength="50" placeholder="请填写..."></Input>
          </FormItem>
        </Col>
        <Col span="12" class-name="form-item-col">
          <FormItem label="手机号">
            <Input v-model="formItem.phone" maxlength="11" placeholder="请填写..."></Input>
          </FormItem>
        </Col>
        <Col span="12" class-name="form-item-col">
          <FormItem label="电子邮箱">
            <Input v-model="formItem.email" maxlength="30" placeholder="请填写..."></Input>
          </FormItem>
        </Col>
        <Col span="24" class-name="form-item-col">
          <FormItem label="您还有什么话想对我们说？">
            <Input maxlength="1000" v-model="formItem.talkToMe" type="textarea" :autosize="{minRows: 2,maxRows: 5}"
              placeholder="请填写..."></Input>
          </FormItem>
        </Col>
        <Col span="24" class-name="form-item-col">
          <FormItem label="">
            <Button @click="onSubmit()" size="large" type="primary">&nbsp;&nbsp;&nbsp;&nbsp;提&nbsp;&nbsp;&nbsp;&nbsp;交&nbsp;&nbsp;&nbsp;&nbsp;</Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  </div>
</template>

<script>
import { insertPerson } from '@/api/api'
import { checkPhone, validateEmail } from '@/utils/validate'

export default {
  name: 'helpForm',
  data () {
    return {
      formItem: {
        name: '',
        companyName: '',
        phone: '',
        email: '',
        talkToMe: ''
      }
    }
  },
  methods: {
    onSubmit () {
      if (!this.formItem.name) {
        return this.$Message.warning('请输入姓名')
      }
      if (!this.formItem.companyName) {
        return this.$Message.warning('请输入公司名称')
      }
      if (!this.formItem.phone && !this.formItem.email) {
        return this.$Message.warning('请输入手机号或者邮箱')
      }
      if (this.formItem.phone && !checkPhone(this.formItem.phone)) {
        return this.$Message.warning('手机号码格式错误，请重新输入')
      }
      if (this.formItem.email && !validateEmail(this.formItem.email)) {
        return this.$Message.warning('邮箱格式错误，请重新输入')
      }
      if (!this.formItem.talkToMe) {
        return this.$Message.warning('请描述您的问题')
      }
      insertPerson(this.formItem).then(() => {
        this.$Modal.success({
          title: '提交成功',
          content: '感谢提交，稍后我们会与您联系。',
          scrollable: true
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ivu-row {
    .ivu-col {
      .ivu-form-item {
        .ivu-form-item-label {
          color: #FFFFFF !important;
          font-size: 18px !important;
          line-height: 32px !important;
        }

        .ivu-input {
          height: 64px;
          padding: 0 32px;
          color: #FFFFFF;
          background: rgba(255, 255, 255, 0.06) !important;
          border-radius: 8px !important;
          border: 1px solid rgba(255, 255, 255, 0.2) !important;
        }

        .ivu-input-type-textarea .ivu-input {
          padding: 18px 32px;
          height: 138px !important;
          color: #FFFFFF;
        }
      }
    }
  }
}

.help-form {
  margin-top: 80px;

  .title {
    .en {
      font-size: 14px;
      color: #999999;
    }

    .zh {
      font-size: 32px;
      color: #FFFFFF;
    }
  }

  .form1 {
    margin-top: 40px;
  }
}
</style>
