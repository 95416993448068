<template>
  <div class="fire_container sky-Insight">
    <div class="flex-row-space-between-hCenter header">
      <div class="flex-column title">
        <span class="en">SkyInsight</span>
        <span class="zh">来SkyInsight洞观火情</span>
      </div>
      <button @click="onInsight" class="experience-btn">体验入口</button>
    </div>
    <div class="flex-row-space-between-hCenter insight-list">
      <div class="flex-row item" v-for="item in insightList" :key="item.id">
        <div class="flex-column left">
          <span class="title">{{ item.title }}</span>
          <span class="characteristic-btn">产品特点</span>
        </div>
        <div class="flex-center-row right">
          <span class="six-line1">{{ item.content }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'skyInsight',
  data () {
    return {
      insightList: [
        {
          id: '21',
          title: '靠得住的1公里',
          content: 'SkyInsight的火点研判空间分辨率高达1km，远远优于当前业界的3公里*3公里的水平，“火情信息靠得住，管理人员不白跑”是SkyInsight的自我要求，经由温州市文成县应急管理局验证；'
        },
        {
          id: '22',
          title: '了不起的15分钟',
          content: '争分夺秒，采用更加先进的感知算法，帮助用户更快感知到火情信息（相较于传统火情监测方法，可以提速15分钟及以上），提升预警研判能力；'
        },
        {
          id: '23',
          title: '跑得动的全流程',
          content: '基于多年基层服务经验，SkyInsight充分理解应急管理、基层救援的业务流程，可以实现对应区域的防火人员、上级指挥人员、总指挥中心等各个环节发出及时的火情信息并串联灾情救援与灾后重建的各项工作，为政府提供火情救灾的业务闭环。'
        }
      ]
    }
  },
  methods: {
    onInsight () {
      window.open('https://starvision.answer12.com', '_self')
    }
  }
}
</script>

<style lang="scss" scoped>
.sky-Insight {
  margin-top: 80px;

  .header {
    .title {
      .en {
        font-size: 14px;
        color: #999999;
      }

      .zh {
        font-size: 32px;
        color: #FFFFFF;
      }
    }

    .experience-btn {
      width: 200px;
      height: 64px;
      font-size: 25px;
      color: #FFFFFF;
      text-align: center;
      line-height: 64px;
      border: none;
      background-image: linear-gradient(to right, #7D57FF, #30D4D4);
      cursor: pointer;

      &:hover {
        background-image: linear-gradient(to right, #7D57FF, #7D57FF, #7D57FF, #30D4D4);
      }
    }
  }

  .insight-list {
    margin-top: 32px;

    .item {
      width: calc(33.33% - 12px);
      height: 222px;
      background-image: url("https://forest-fire.oss-cn-hangzhou.aliyuncs.com/upload/20230416/2ae639cf572ab10c0283eb1d8621cd15.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .left {
        width: 150px;
        padding: 124px 0 0 24px;

        .title {
          font-size: 16px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 28px;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
        }

        .characteristic-btn {
          width: 60px;
          height: 24px;
          margin-top: 10px;
          background: #EEEFFF;
          box-shadow: 0 0 6px 0 rgba(255, 255, 255, 0.4);
          border-radius: 4px;
          font-size: 11px;
          color: #333333;
          text-align: center;
          line-height: 24px;
        }
      }

      .right {
        flex: 1;
        padding-right: 24px;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 24px;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
        word-break: break-all;
      }

      &:nth-child(2) {
        background-image: url("https://forest-fire.oss-cn-hangzhou.aliyuncs.com/upload/20230416/5533af5ce47e253cb4d1db8ff0498849.png");
      }

      &:nth-child(3) {
        background-image: url("https://forest-fire.oss-cn-hangzhou.aliyuncs.com/upload/20230416/7a3e8120a34d8c7a2e73b9dbe67a2b55.png");
      }
    }
  }
}
</style>
