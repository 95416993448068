<template>
  <div class="footer-com">
    <appContent v-if="isMobileDevice()"></appContent>
    <pcContent v-else></pcContent>
  </div>
</template>

<script>
import appContent from './appContent'
import pcContent from './pcContent'
import { isMobileDevice } from '@/utils/common'

export default {
  components: {
    appContent,
    pcContent
  },
  data () {
    return {}
  },
  methods: {
    isMobileDevice
  }
}
</script>

<style lang="scss" scoped>
.footer-com {
  overflow: hidden;
  background: #0E0E11;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.12);
}
</style>
