<template>
  <div class="header-com" :style="{padding: isMobileDevice()? '0 12px': '0 50px'}">
    <appContent v-if="isMobileDevice()"></appContent>
    <pcContent v-else></pcContent>
  </div>
</template>

<script>
import appContent from '@/components/header/appContent'
import pcContent from '@/components/header/pcContent'
import { isMobileDevice } from '@/utils/common'
export default {
  components: {
    appContent,
    pcContent
  },
  methods: {
    isMobileDevice
  }
}
</script>

<style lang="scss" scoped>
.header-com {
  position: fixed;
  top: 0;
  width: 100%;
  height: 64px;
  padding: 0 12px;
  background: #0E0E11;
  z-index: 9999;
}
</style>
