// 小于10时在前面加个0
function addZero (num) {
  if (num < 10) {
    return `0${num}`
  }
  return num
}

// 格式化日期
export function formatDate (date, fmt) {
  if (!date) {
    date = new Date()
  }
  // 防止苹果设备不支持-分割的时间
  if (typeof date === 'string') {
    const reg = /-/g
    date = date.replace(reg, '/')
  }

  const dateFormat = new Date(date)
  const year = dateFormat.getFullYear()
  const month = addZero(dateFormat.getMonth() + 1)
  const day = addZero(dateFormat.getDate())
  const hour = addZero(dateFormat.getHours())
  const minute = addZero(dateFormat.getMinutes())
  const second = addZero(dateFormat.getSeconds())

  let resultDate
  switch (fmt) {
    case 'yyyy':
      resultDate = year
      break
    case 'yyyymmdd':
      resultDate = `${year}${month}${day}`
      break
    case 'mm.dd':
      resultDate = `${month}.${day}`
      break
    case 'mm-dd':
      resultDate = `${month}-${day}`
      break
    case 'yyyy-mm-dd':
      resultDate = `${year}-${month}-${day}`
      break
    case 'yyyy/mm/dd':
      resultDate = `${year}/${month}/${day}`
      break
    case 'yyyy.mm.dd':
      resultDate = `${year}.${month}.${day}`
      break
    case 'yyyy年mm月dd日':
      resultDate = `${year}年${month}月${day}日`
      break
    case 'object':
      resultDate = {
        year: dateFormat.getFullYear(),
        month: dateFormat.getMonth() + 1,
        day: dateFormat.getDate(),
        hour: dateFormat.getHours(),
        minute: dateFormat.getMinutes(),
        second: dateFormat.getSeconds()
      }
      break
    default:
      resultDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`
      break
  }
  return resultDate
}

// 根据开始时间计算结束时间
export function getEndTime (startTime, day) {
  if (!startTime) {
    startTime = new Date()
  }
  // 防止苹果设备不支持-分割的时间
  if (typeof startTime === 'string') {
    const reg = /-/g
    startTime = startTime.replace(reg, '/')
  }
  const startTimeStamp = new Date(startTime).getTime()
  const timeInterval = 86400000 * day
  return formatDate(startTimeStamp + timeInterval, 'yyyy-mm-dd')
}

/**
 * 格式化持续时间
 * time 单位秒
 */
export function durationFormat (time) {
  time = time || 0
  let minute = parseInt(time / 60)
  minute = minute < 10 ? '0' + minute : minute
  let second = parseInt(time % 60)
  second = second < 10 ? '0' + second : second
  const timeFormat = `${minute}:${second}`
  return timeFormat
}
