import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dictionary: ''
  },
  getters: {
    dictionary: state => state.dictionary
  },
  mutations: {
    /**
     * 设置字典
     * */
    setDict (state, data) {
      state[data.code] = data.data
    }
  },
  actions: {},
  modules: {}
})
