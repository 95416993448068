<template>
  <div class="home-index">
    <headerCom></headerCom>
    <div class="main-contain">
      <!--实时播报-->
      <realTimeBroadcast></realTimeBroadcast>
      <!--来SkyInsight洞观火情-->
      <skyInsight></skyInsight>
      <!--产品功能-->
      <productFeatures></productFeatures>
      <!--我们可以为您做点什么-->
      <helpForm></helpForm>
    </div>
    <footerCom></footerCom>
  </div>
</template>

<script>
import headerCom from '@/components/header'
import footerCom from '@/components/footer'
import realTimeBroadcast from './component/realTimeBroadcast'
import skyInsight from './component/skyInsight'
import productFeatures from './component/productFeatures'
import helpForm from './component/helpForm'

export default {
  name: 'homeIndex',
  components: {
    headerCom,
    footerCom,
    realTimeBroadcast,
    skyInsight,
    productFeatures,
    helpForm
  },
  data () {
    return {}
  },
  mounted () {
    // 页面整体缩放0.8倍
    // const size = 0.8
    // document.body.style.zoom = size
    // document.body.style.cssText += '; -moz-transform: scale(' + size + ');-moz-transform-origin: 0 0; '
  }
}
</script>

<style lang="scss" scoped>
.home-index {
  position: relative;
}

.main-contain {
  padding-top: 64px;
  overflow: hidden;
  background: #151518 url("https://forest-fire.oss-cn-hangzhou.aliyuncs.com/upload/20230416/31ce6d0dc35b8fb8ead3a808b732561c.png") top center no-repeat;
  background-size: 100% auto;
}
</style>
