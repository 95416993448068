import axios from 'axios'
import store from '@/store'
// import { Notify, Toast } from 'vant'

const REQUEST_BASE_URL = ''

const service = axios.create({
  baseURL: REQUEST_BASE_URL,
  timeout: 2000000
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers.Authorization = 'Basic amlzaGlmdTpqaXNoaWZ1bWluaV9zZWNyZXQ='
    config.headers['Tenant-Id'] = '000000'

    const user = store.getters.userInfo || {}
    if (user.access_token) {
      config.headers['GeekMore-Auth'] = user.access_token
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // console.log('response')
    // console.log(response)

    const res = response.data

    // if the custom code is not 200, it is judged as an error.
    if (res.code !== 200) {
      if (res.code === 450) {
        const error = {
          code: res.code,
          msg: res.msg
        }
        return Promise.reject(error)
      }
      const { msg } = res
      // Notify({
      //   type: 'danger',
      //   message: msg
      // })
      return Promise.reject(new Error(msg || 'Error'))
    } else {
      return res.data
    }
  },
  error => {
    const { response } = error || {}
    const { data } = response || {}
    const { code } = data
    if (code === 400) {
      // Toast.fail(data.msg)
    }
    return Promise.reject(data)
  }
)

export default service
