import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index/index'
import newsDetailView from '../views/news/detail'
const AppHomeView = () => import('../views/appIndex/index')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: HomeView
  },
  {
    path: '/index',
    name: 'index',
    component: HomeView
  },
  {
    path: '/appIndex',
    name: 'appIndex',
    component: AppHomeView
  },
  {
    path: '/news/detail',
    name: 'newsDetail',
    component: newsDetailView
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
