<template>
  <div class="fire_container product-features">
    <div class="flex-column title">
      <span class="en">Product Features</span>
      <span class="zh">产品功能</span>
    </div>
    <div class="product-item" v-for="item in productList" :key="item.id">
      <img class="bg-img" :src="item.bg" alt="">
      <div class="flex-column item-content">
        <span class="title">{{ item.title }}</span>
        <span class="line"></span>
        <span class="desc">{{ item.content }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'productFeatures',
  data () {
    return {
      productList: [
        {
          id: '31',
          title: '态势感知',
          bg: 'https://forest-fire.oss-cn-hangzhou.aliyuncs.com/image/product_features1.png',
          content: '实时动态全面掌握区域内气象、天气、火情等资讯，并形成有效的智能风险指数，为决策提供数据支撑；'
        },
        {
          id: '32',
          title: '灾前预警',
          bg: 'https://forest-fire.oss-cn-hangzhou.aliyuncs.com/image/product_features2.png',
          content: '综合利用卫星监测、烟感传感器、监控摄像头、人工上报等多项数据信息来源，SkyInsight可以快速对高风险地区形成智能判断，提前形成风险信息的分发与告警；'
        },
        {
          id: '33',
          title: '灾中监测与救援',
          bg: 'https://forest-fire.oss-cn-hangzhou.aliyuncs.com/image/product_features3.png',
          content: 'SkyInsight具备自有智能卫星资源，可以不受灾害影响实现从太空观测地表，同步实时灾害信息至应急管理指挥部，同时，SkyInsight支持应急无人机激光雷达等数据接入，为全面把握灾情信息提供更多可能。同时给出灾中可行的救援线路与推荐的隔离带，辅助消防人员进行灾中救援；'
        },
        {
          id: '34',
          title: '灾后分析',
          bg: 'https://forest-fire.oss-cn-hangzhou.aliyuncs.com/image/product_features4.png',
          content: '结合实景三维真实还原现场，SkyInsight持续对灾害过火面积、烧伤程度、复燃概率等进行监测，判断是否存在二次灾害的可能性，为准确评估灾损、制定灾后方案提供数字沙盘支撑。'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.product-features {
  margin-top: 80px;

  .title {
    .en {
      font-size: 14px;
      color: #999999;
    }

    .zh {
      font-size: 32px;
      color: #FFFFFF;
    }
  }

  .product-item {
    position: relative;
    height: 340px;
    margin-top: 40px;
    padding: 50px;

    &:nth-child(2n) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .bg-img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .item-content {
      width: 530px;
      z-index: 99;
      position: relative;
      height: 100%;

      .title {
        font-size: 36px;
        color: #FFFFFF;
        line-height: 42px;
      }

      .line{
        width: 63px;
        height: 4px;
        margin-top: 14px;
        background: #7DC1FF;
      }

      .desc{
        margin-top: 12px;
        font-size: 16px;
        color: rgba(255,255,255,0.8);
        line-height: 40px;
      }
    }
  }
}
</style>
