<template>
  <div class="fire_content">
    <div class="title">
      <span>联系我们</span>
    </div>
    <Row class-name="form-item" :gutter="20">
      <Col span="24" class-name="form-item-col">
        <span class="footer-item">地卫二空间技术杭州有限公司</span>
      </Col>
      <Col span="24" class-name="form-item-col">
        <span class="footer-item">电话：0571-86227683</span>
      </Col>
      <Col span="24" class-name="form-item-col">
        <span class="footer-item">邮箱：SV@STAR.VISION</span>
      </Col>
      <Col span="24" class-name="form-item-col">
        <span class="footer-item">地址：中国（杭州）算力小镇 A座 4楼</span>
      </Col>
    </Row>
  </div>
</template>

<script>
export default {
  name: 'appContent'
}
</script>

<style lang="scss" scoped>
.fire_content{
  padding: 0 12px 24px;
}
.footer-com {
  overflow: hidden;
  background: #0E0E11;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.12);

  .title {
    margin-top: 28px;
    font-size: 22px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 64px;
    border-bottom: 1px solid rgba(180, 180, 181, 0.24);
  }

  .form-item {
    margin-top: 14px;

    .footer-item {
      font-size: 16px;
      font-weight: 400;
      color: #F4F6FC;
      line-height: 28px;
    }
  }
}
</style>
